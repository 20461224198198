import { render, staticRenderFns } from "./connect-wallet.vue?vue&type=template&id=6e2c3ef3&scoped=true&"
import script from "./connect-wallet.vue?vue&type=script&lang=ts&"
export * from "./connect-wallet.vue?vue&type=script&lang=ts&"
import style0 from "./connect-wallet.vue?vue&type=style&index=0&id=6e2c3ef3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e2c3ef3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCard,VCol,VDialog,VIcon,VImg,VProgressCircular,VRow,VSheet})
