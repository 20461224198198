






































































































































































































































































































import { Component, Vue, Provide } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {},
})
export default class ConnectWallet extends Vue {
  @Provide() walletStore = walletStore
  copyAddress() {
    if (this.walletStore.account) {
      navigator.clipboard.writeText(this.walletStore.account)
    }
  }

  gotoRegister() {
    this.$router.push('/register')
    this.walletStore.changeShowConnectDialog(false)
  }

  async login() {
    const res = await this.walletStore.signIn()
    if (res) this.walletStore.changeShowConnectDialog(false)
  }

  async submitKyc() {
    if (!this.walletStore.synapsClient) {
      await walletStore.createSynapsSession()
    }
    document.getElementById('synaps-btn')?.click()
  }
}
